import React, { useRef, useState, useEffect } from "react"

import valentinesMatchstick from "../images/Matchmaker 21 Logo.png"
import valentinesLogo from "../images/matchmaker-provo-name.png"
import Footer from "./footer"
import CountDown from "./countdown"

export default function ValentinesLanding({
  onAnimationEnd,
  className,
  showVerify,
}) {
  const [fadeOutIsComplete, setFadeOutIsComplete] = useState(false)

  function finishAnimation() {
    setFadeOutIsComplete(true)
    onAnimationEnd()
  }

  const SURVEY_LAUNCH_DATE = new Date(2021, 0, 23, 0, 0, 0, 0)
  const MATCH_DROP_DATE = new Date(2020, 10, 13, 23, 59, 0)

  return (
    <div
      className={
        "max-w-6xl m-auto flex justify-center flex-col align-middle " +
        (fadeOutIsComplete ? "hidden" : className)
      }
      onAnimationEnd={finishAnimation}
    >
      <div className="mt-20 md:mt-48">
        <center>
          <div className="responsive-1">
            <img
              className="matchstick-1 px-10"
              src={valentinesMatchstick}
              alt="matchstick"
            ></img>
            <div className="logo-1 flex flex-col justify-center">
              <img className="mt-10 md:mt-20" src={valentinesLogo}></img>
              <div className="w-full">
                <p className="text-answer theme-gray">This round of matchmaker is now closed!</p>
                <br/>
                <p className="text-answer theme-gray">Keep an eye on your inbox for your 4 matches! And good luck finding your valentine ;)</p>
              </div>
              <br></br>
            </div>
          </div>
        </center>
      </div>
      <div></div>
      <Footer
        className={`${
          process.env.GATSBY_ALLOW_THROUGH_COUNTDOWN === "false" ? "hidden" : ""
        }`}
      ></Footer>
    </div>
  )
}
